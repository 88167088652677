<!-- Finder particular de Funerarias Centrales -->

<template>
  <div class="funec_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">
      
        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">
          <base_Header
            :Entorno="Entorno.header"
            @onEvent="event_Header">
          </base_Header>
        </div>

        <!-- Filtro -->
        <div class="contenedor"  style="width:950px"> 

          <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div class="filtro contenido">
              <v-row no-gutters>
                <v-col md="6">
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.funeraria.value"
                    :label="schema.ctrls.funeraria.label"
                    @keypress.13="event_search('buscarFunerariaC')">                
                  </v-text-field>
                </v-col>

                <v-col md="1">
                  <v-btn
                    v-bind="$cfg.btn.busca"
                    @click="event_search('buscarFunerariaC')">                
                      <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col md="6">
                  <ctrlfinder finderName="atlas_F" :schema="schema.ctrls.atlas">
                  </ctrlfinder>
                </v-col>
              </v-row>            
            </div>
          </div>        

          <!-- Grid -->
          <base_Fgrid 
            :padre="stName" 
            :Entorno="Entorno" 
            dense
            @onEvent="event_Grid">
            
            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">         
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>

      <!-- Mto como ventana modal -->      
      <v-dialog
        v-model="modal">
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>  

    </div>
  </div>
</template>



<script>
  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const funec_M = () => plugs.groute("funec_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, ctrlfinder, funec_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}

    },

    data() {
      return {
        schema:null,
        api:'funec_F',
        stName:'stFfunec',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {},
      };
    },


    methods: {
      async ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno         
        this.Entorno.header.titulo = "Funerarias Centrales";
        
        this.headers= {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Funeraria", value: "name", width: "40%" },
            { text: "Situación", value: "situ", width: "10%" },
            { text: "Atlas", value: "atlas", width: "40%" }           
          ]
        }

        this.Entorno.grid.headers = this.set_headers();
        
        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'funec_M';
      },


      // Recoge eventos de busqueda
      event_search(nameBtn, header) {
        //var ctrls= this.schema.ctrls;

        /* if (ctrls.funeraria.value.length< 3 && ctrls.atlas.value== "") {
          this.$root.$alert.open('Debe introducir una busqueda válida', 'info');
          return;
        } */

        this.buscarExec(nameBtn, header);       
      },

    }
  };
</script>
